import { ContextualEnvironment } from '@pv-frontend/contextual-journey/interface/contextual-journey.interface';
import { GvEnvironment } from '@pv-frontend/gift-voucher-journey';

interface EnvInterface extends GvEnvironment {
  clientId: string;
  projectId: string;
  countryId: string;
  corePathV2: string;
  production: boolean;
  sentryDsn: string;
}

// TODO: rename the variables with proper convention, blocker for this is razorpay-pg lib.
export const environment: EnvInterface &
  ContextualEnvironment & { referralCampaignID: string } = {
  production: true,
  sentryDsn:
    'https://81a0f9847a3c77d9b7fed9e8ef8bdd7a@o515678.ingest.us.sentry.io/4507424366264320',
  apiPerksServiceUrl: 'https://api-customer.poshvine.com/ps/v1',
  apiPerksServiceUrlV2: 'https://api-customer.poshvine.com/ps/v2',
  apiClientsServiceUrl: 'https://api-customer.poshvine.com/cs/v1',
  apiUrlPaV1: 'https://api-customer.poshvine.com/pa/v1/',
  apiBookingServiceUrl: 'https://api-customer.poshvine.com/bs/v1',
  clientId: 'e95ad93b-3ba6-4c58-8680-3c6e3f624b72',
  projectId: '9adf7103-940d-4e2b-8f07-80cbc86944c5',
  countryId: '625a3e8e-e493-421a-8ece-609f10b37442',
  campaignPath: 'https://api-customer.poshvine.com/cas/v1/',
  campaignEnterprisePath: 'https://api-customer.poshvine.com/cas/enterprise/',
  coreServicePath: 'https://api-customer.poshvine.com/core_service/v1',
  apiLoyaltyServiceUrl: 'https://api-customer.poshvine.com/ls/v1',
  referralCampaignID: '123d3c13-b41d-4617-b48e-2e825fe51584',
  corePathV2: 'https://api-customer.poshvine.com/cs/v1',
  apiBookingServiceUrlV2: 'https://api-customer.poshvine.com/bs/v1',
  apiUrlLsV1: '',
};
