import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { SharedGVLogicService } from '@pv-frontend/gift-voucher-journey';
import { landingConfigOverride } from '@pv-frontend/gift-voucher-journey/home/home.interface';

@Component({
  selector: 'hdfc-rupay-benefits-carousel',
  templateUrl: './benefits-carousel.component.html',
  styleUrls: ['./benefits-carousel.component.scss'],
})
export class BenefitsCarouselComponent {
  @Input() public title: string = '';
  @Input() public moduleConfig?: Partial<landingConfigOverride> = {};
  @Input() public titleContentTemplate?: TemplateRef<unknown>;
  @Input() public showArrows = true;
  public isStepsContainerMaxWidthReached = false;
  public isStepsContainerMinWidthReached = true;
  @ViewChild('stepsContainerRef') public stepsContainer?: ElementRef;

  public constructor(private sharedLogicService: SharedGVLogicService) {}

  public handleRedeemWrapperGCAndMemberships(
    id: string,
    merchantName: string,
    isUnlockedBenefitApplicable?: boolean
  ): void {
    this.sharedLogicService.handleRedeemGv(id, merchantName, false, '', {
      isUnlockedBenefitApplicable,
    });
  }
  protected scrollHorizontal(isLeft: boolean): void {
    this.stepsContainer?.nativeElement.scrollBy({
      left: isLeft ? -700 : 700,
      behavior: 'smooth',
    });
    /* Added setTimeout to cater to the lag added in scroll positions due to behavior:"smooth" in
    scrollBy() */
    setTimeout(() => {
      this.isStepsContainerMaxWidthReached =
        this.stepsContainer?.nativeElement.scrollLeft ===
        this.stepsContainer?.nativeElement.scrollWidth -
          this.stepsContainer?.nativeElement.offsetWidth;
      this.isStepsContainerMinWidthReached =
        this.stepsContainer?.nativeElement.scrollLeft === 0;
    }, 300);
  }
}
