<section
  class="flex info-container min-h-screen justify-content-center align-items-start flex-row w-screen">
  <div class="w-11 md:pv-mb-40px">
    <div class="pv-pt-40px flex justify-content-between">
      <div class="hidden md:block w-3">
        <div class="info-left-list pv-text-18px hidden md:block">
          <p class="block m-0 pv-py-12px">
            <a
              routerLink="/info/terms-and-conditions"
              routerLinkActive="infoLinkActive">
              <span class="left">Terms and conditions</span>
            </a>
          </p>
          <p class="block m-0 pv-py-12px">
            <a
              routerLink="/info/customer-support"
              routerLinkActive="infoLinkActive">
              <span class="left">Customer support</span>
            </a>
          </p>
        </div>
      </div>
      <div class="w-12 md:w-8">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</section>
