import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'hdfc-rupay-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  public constructor(
    private meta: Meta,
    private pageTitle: Title
  ) {}

  public ngOnInit(): void {
    const title = 'Customer Support';
    this.pageTitle.setTitle(title);
    this.meta.updateTag({ name: 'description', content: title });
  }
}
