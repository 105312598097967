<div>
  <h1>Customer Support</h1>
  <div class="profile-right-form">
    <div style="margin-top: 20px">
      <p class="paragraph">In case of any queries, please contact:</p>
      <ul class="mar-top-10">
        <li>
          <strong class="f700 strong inline-block">Email address:</strong>
          <a href="mailto:support@poshvine.com">support&#64;poshvine.com</a>
        </li>
      </ul>
    </div>
  </div>
</div>
