<p class="font-semibold">Bumper Surprise T&C:</p>
<ul>
  <li>
    This offer is valid on online retail spends,bill payments, pos machine
    swipes(not cash withdrawal) made through HDFC Bank Credit Cards.
  </li>
  <li>
    For the customers who meet the contest target, voucher will be processed and
    sent within 90 days from offer end date.
  </li>
</ul>

<p>
  <strong>{{ title }}</strong>
</p>
<ul>
  <li *ngFor="let term of termsArr">
    {{ term }}
  </li>
</ul>
