import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCardsResponse } from '@pv-frontend/authentication';
import {
  DeviceService,
  GTMTrackLabels,
  SharedGVLogicService,
  UserCardPointsResponse,
} from '@pv-frontend/gift-voucher-journey';
import { landingConfigOverride } from '@pv-frontend/gift-voucher-journey/home/home.interface';
import { BenefitResponseDisp } from '@pv-frontend/gift-voucher-journey/interfaces/benefit-response';
import {
  CategoryIconInput,
  ChipStyleOverride,
} from '@pv-frontend/pv-shared-components/chip';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import { UserCard } from '@pv-frontend/pv-shared-services/common';
import {
  JourneyMetadataService,
  JOURNEY_METADATA_SERVICE_TOKEN,
} from '@pv-frontend/pv-shared-services/journey-metadata-service';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { MessageService } from 'primeng/api';
import { RazorpayPgService } from 'razorpay-pg';
import { Subject, finalize, takeUntil } from 'rxjs';

import { APIService } from '../api.service';
import { AllBenefitsResponse, PaymentObject } from '../hdfc.types';

@Component({
  selector: 'hdfc-rupay-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public getStartedButtonStyleOverride: OverrideStyleConfig = {
    fontSize: '20px',
    padding: '15px 24px',
  };
  public chipIconObject: CategoryIconInput = {
    url: '../../assets/star.svg',
    position: 'start',
  };
  public chipStyle: ChipStyleOverride = {
    container: {
      border: '0',
      fontStyle: '14px',
      color: 'var(--black)',
      width: 'max-content',
      background: 'var(--green-100)',
      padding: '6px 12px',
    },
    categoryIcon: {
      height: '20px',
      width: '20px',
    },
  };
  public showGvRedemption: boolean = false;
  public isBenefitOffer = false;
  public usePoints = 0;
  public selectedBenefitId = '';
  public offerId?: string;
  public customCurrencyRupeeChipStyle: ChipStyleOverride = {
    container: {
      border: '1px solid var(--text-white-alpha-20)',
      fontStyle: '26px',
      color: 'var(--white)',
      width: 'max-content',
      background: 'var(--primary-color)',
      padding: '8px 12px',
    },
    categoryIcon: {
      height: '16px',
      width: '16px',
      marginRight: '4px',
    },
  };
  public moduleConfig?: Partial<landingConfigOverride> = {};
  public askForPennyDropModalStyles: OverrideStyleConfig = {
    minHeight: '55vh',
    maxHeight: '400px',
    padding: '0',
    borderTopLeftRadius: '14px',
    borderTopRightRadius: '14px',
  };
  public arePointsLoading: boolean = true;
  public userCard: UserCard | undefined = undefined;
  public parsedResponse: BenefitResponseDisp[] = [];
  public peakCards: { title: string; subTitle: string }[] = [
    {
      title: 'Festival Offers',
      subTitle: 'Coming Soon',
    },
    {
      title: 'Festival Offers',
      subTitle: 'Coming Soon',
    },
    {
      title: 'Festival Offers',
      subTitle: 'Coming Soon',
    },
    {
      title: 'Festival Offers',
      subTitle: 'Coming Soon',
    },
  ];
  public isBeneiftLoading: boolean = false;
  public isPennyDropComplete: boolean = false;
  public isPennyDropStatusLoading: boolean = true;
  public areBenefitsLoading: boolean = false;
  public isAskForPennyDropOpen: boolean = false;
  private destroy$: Subject<void> = new Subject<void>();
  public showEmailVerificationModal: boolean = false;
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private apiService: APIService,
    private sharedLogicService: SharedGVLogicService,
    @Inject('gtmLabels') public gtmLabels: GTMTrackLabels,
    @Inject(JOURNEY_METADATA_SERVICE_TOKEN)
    public journeyMetadataService: JourneyMetadataService,
    public deviceService: DeviceService,
    private router: Router,
    private pgService: RazorpayPgService,
    private changeDetector: ChangeDetectorRef,
    private messageService: MessageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.moduleConfig = this.journeyMetadataService.getJourneyConfig(
      'journey.landingPage'
    ) as Record<string, unknown>;
    this.pgService.paymentModalClosedEvent.subscribe(() => {
      this.changeDetector.detectChanges();
    });
    if (!this.deviceService.isCurrentDeviceMobile()) {
      this.askForPennyDropModalStyles = {
        minHeight: '55vh',
        maxHeight: '400px',
        maxWidth: '40vw',
        padding: '0',
        borderRadius: '14px',
      };
    }
  }

  public setRupayCreditCard(): Promise<void> {
    return new Promise((resolve) => {
      this.apiService
        .getUserCards()
        .subscribe((cardsreponse: UserCardsResponse) => {
          this.pvUserService.setUserCards(cardsreponse.data);
          this.userCard = this.pvUserService
            .getUserCards()
            ?.find(
              (card: UserCard) => card.attributes.status === 'payment_verified'
            );

          if (this.userCard) {
            this.pvUserService.setSelectedUserCard(this.userCard);
            this.showEmailVerificationModal =
              !this.pvUserService.getUserDetails()?.email;
          }
          resolve();
        });
    });
  }

  public handleEmailSavedSuccessfullyEvent(email: string): void {
    const tempUserDetails = this.pvUserService.getUserDetails();
    if (tempUserDetails) {
      this.pvUserService.setUserDetails({
        ...tempUserDetails,
        email,
      });
    }

    this.showEmailVerificationModal = false;
  }
  public logoutUser(): void {
    this.pvUserService.logoutUser();
    this.apiService.reloadHeaderEvent.next();
    localStorage.clear();
    this.messageService.add({
      severity: 'error',
      summary: 'You need a Rupay Card to access this portal',
    });
    this.router.navigate(['login']);
  }

  public async ngOnInit(): Promise<void> {
    this.userCard = this.pvUserService.getSelectedUserCard() ?? undefined;
    if (
      !this.userCard ||
      this.activatedRoute.snapshot.queryParams['payment_verified']
    ) {
      await this.setRupayCreditCard();
    }
    if (this.userCard) {
      this.showEmailVerificationModal =
        !this.pvUserService.getUserDetails()?.email;
    }
    this.apiService
      .getUserPoints()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.arePointsLoading = false;
        })
      )
      .subscribe((resp: UserCardPointsResponse) => {
        if (resp.points) {
          this.usePoints = resp.points;
        }
      });

    this.isPennyDropComplete =
      this.userCard?.attributes.status === 'payment_verified';
    this.isPennyDropStatusLoading = false;
    this.fetchAndSortBenefits();
  }

  private fetchAndSortBenefits(): void {
    this.areBenefitsLoading = true;
    this.apiService
      .getAllBenefits({
        per_page: 100,
      })
      .pipe(
        finalize(() => {
          this.areBenefitsLoading = false;
        })
      )
      .subscribe((res: AllBenefitsResponse) => {
        if (res.data.length) {
          /* This sorting is needed as BE cant handle it for now */
          const sortingOrderPrecedenceArray = [
            'Amazon Shopping Voucher',
            'Amazon Pay eGift Card',
            'Amazon Pay E-Gift Card',
            'Flipkart E-Gift Card',
          ];
          const benefitsArray: BenefitResponseDisp[] = [];
          benefitsArray.push(
            ...res.data.map((element) =>
              this.sharedLogicService.generateBenefitCardData(element)
            )
          );
          if (benefitsArray?.length) {
            benefitsArray.sort((benefit) =>
              sortingOrderPrecedenceArray?.includes(benefit.merchantName ?? '')
                ? -1
                : 1
            );
            this.parsedResponse = benefitsArray;
          }
        }
      });
  }

  public initiatePennyDrop(): void {
    if (this.isPennyDropComplete) {
      return;
    }
    const user = this.pvUserService.getUserDetails();
    const paymentDetails: PaymentObject = {
      amount: 1,
      vcp: 'vp',
      first_name: user?.first_name,
      email: user?.email,
      phone: user?.mobile,
      product_info: '',
      transactions_attributes: [
        {
          txn_type: 'payment_gateway',
          amount: 1,
        },
      ],
    };

    try {
      this.pgService.makePayment(paymentDetails);
    } catch (error) {
      if (error && typeof error == 'string') {
        this.messageService.add({
          severity: 'error',
          summary: 'Something went wrong',
        });
      }
    }
  }

  public handleRedeemWrapperGCAndMemberships(
    merchantId: string,
    merchantName: string,
    isUnlockedBenefitApplicable?: boolean
  ): void {
    if (!this.isPennyDropComplete) {
      this.isAskForPennyDropOpen = true;
      return;
    }
    const queryParams = {
      merchantId,
      merchantName,
      ...{ isBenefitOffer: false },
      isUnlockedBenefitApplicable,
    };
    if (this.deviceService.isMobile) {
      this.showGvRedemption = true;
      this.selectedBenefitId = merchantId;
      this.isBenefitOffer = false;
      this.offerId = '';
    } else {
      this.handleGVRedemptionClose(false);
      this.router.navigate(['gvms-redeem'], {
        queryParams: queryParams,
      });
    }
  }

  public handleGVRedemptionClose(showRedemption: boolean): void {
    this.showGvRedemption = showRedemption;
    this.selectedBenefitId = '';
  }
  public handleClosePennyDropModal(): void {
    this.isAskForPennyDropOpen = false;
    this.initiatePennyDrop();
  }
  public dismissPennyDropModal(): void {
    this.isAskForPennyDropOpen = false;
  }
}
