import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserCardsResponse } from '@pv-frontend/authentication';
import { UserCardPointsResponse } from '@pv-frontend/gift-voucher-journey';
import { BenefitAttributes } from '@pv-frontend/gift-voucher-journey/interfaces/benefit-response';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { Observable, Subject } from 'rxjs';

import { environment } from '../environments/environment';

import { AllBenefitsResponse, EmailIdResponse } from './hdfc.types';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  public reloadHeaderEvent: Subject<void> = new Subject<void>();
  public constructor(
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    private http: HttpClient
  ) {}

  public getAllBenefits(
    params: Record<string, string | number>
  ): Observable<AllBenefitsResponse> {
    return this.http.get<{
      data: {
        id: string;
        attributes: BenefitAttributes;
        type: 'benefit';
      }[];
      meta: Record<string, string>;
    }>(`${environment.apiPerksServiceUrl}/benefits`, {
      params: params,
    });
  }

  public verifyLoginCode(code: string): Observable<{ token?: string }> {
    return this.http.post<{ token?: string }>(
      `${environment.apiClientsServiceUrl}/login_auth_codes/verify`,
      { code }
    );
  }

  public getUserCards(): Observable<UserCardsResponse> {
    return this.http.get<UserCardsResponse>(
      `${environment.apiClientsServiceUrl}/user/user_cards`
    );
  }

  public getUserPoints(): Observable<UserCardPointsResponse> {
    return this.http.get<UserCardPointsResponse>(
      `${environment.apiClientsServiceUrl}/user/points?fetch_points=true`
    );
  }
  public updateEmailId(emailId: string): Observable<EmailIdResponse> {
    return this.http.put<EmailIdResponse>(environment.corePathV2 + '/user', {
      email: emailId,
    });
  }
}
