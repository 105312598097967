import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceService } from '@pv-frontend/gift-voucher-journey';
import { OverrideStyleConfig } from '@pv-frontend/pv-shared-components/common/types';
import {
  PV_USER_SERVICE,
  PvUserService,
} from '@pv-frontend/pv-shared-services/pv-user-service';
import { MessageService } from 'primeng/api';
import { Subject, takeUntil } from 'rxjs';

import { APIService } from '../../api.service';
import { EmailErrors, EmailIdResponse } from '../../hdfc.types';

@Component({
  selector: 'hdfc-rupay-update-email',
  templateUrl: './update-email.component.html',
  styleUrls: ['./update-email.component.scss'],
})
export class UpdateEmailComponent {
  public isEmailVerifyingInProgress = false;
  public emailErrorMessage = '';
  public componentType = false;
  private destroy$: Subject<void> = new Subject<void>();

  public pvDrawerConfig: OverrideStyleConfig = {
    height: 'max-content',
    borderTopRightRadius: '15px',
    borderTopLeftRadius: '15px',
    overflow: 'hidden',
    padding: '0',
  };
  public readonly EMAIL_PATTERN: string =
    '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  public emailIdForm = this.fb.group({
    emailId: new FormControl<string | null>(null, [
      Validators.required,
      Validators.email,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
  });
  public showAuthenticationModal = false;
  @Output() public emailSavedSuccessfullyEvent: EventEmitter<string> =
    new EventEmitter<string>();
  public constructor(
    private fb: FormBuilder,
    private router: Router,
    @Inject(PV_USER_SERVICE) public pvUserService: PvUserService,
    public message: MessageService,
    public apiService: APIService,
    private deviceService: DeviceService
  ) {
    if (!this.pvUserService.getUserDetails()) {
      this.router.navigate(['login']);
    }
    if (!this.deviceService.isMobile) {
      this.pvDrawerConfig = {
        borderRadius: '15px',
        maxWidth: '30%',
        ...this.pvDrawerConfig,
      };
    }
  }

  protected sendEmail(): void {
    if (this.emailIdForm.invalid) {
      this.emailErrorMessage = 'Please enter a valid Email ID';
      return;
    }
    this.emailErrorMessage = '';
    this.apiService
      .updateEmailId(this.emailIdForm.controls.emailId.value ?? '')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: EmailIdResponse) => {
          this.isEmailVerifyingInProgress = false;
          if (response?.success) {
            this.message.add({
              severity: 'success',
              summary: 'Email saved successfully',
            });
            this.emailSavedSuccessfullyEvent.emit(
              this.emailIdForm.controls.emailId.value ?? ''
            );
          } else {
            this.message.add({
              severity: 'error',
              summary: 'Something went wrong',
            });
          }
        },
        error: (response: EmailErrors) => {
          this.isEmailVerifyingInProgress = false;
          this.emailErrorMessage = response?.error?.errors[0] ?? '';
          if (this.emailErrorMessage) {
            setTimeout(() => {
              this.emailErrorMessage = '';
            }, 1000);
          } else {
            this.message.add({
              severity: 'error',
              summary: 'Something went wrong',
            });
          }
        },
      });
  }
}
