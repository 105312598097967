import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'hdfc-rupay-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  public termsArr = [
    'This Offer should be sent to your registered email or mobile number or whatsapp by bank as part of official email or sms from bank. Only then you are eligible for this offer. Kindly check the same.',
    'This offer is valid on retail spends and on transactions made through HDFC Bank Credit Cards within 30 days from receipt of the offer sms. For the customers who meet the contest target, voucher will be processed and sent within 90 days from offer end date.',
    'This offer is not valid on cash withdrawal and loan products like Dial-An-EMI, Cash-on-Call, Balance Transfer, Balance Transfer on EMI, Personal Loan on Credit Card, etc.',
    'Please raise any query pertaining to the offer within 60 days from the offer fulfilment date. Any queries post this period, will not be considered.',
    'Returned purchases, disputed or unauthorized/fraudulent transactions, EMI’s and card membership fees will not be considered for this offer.',
    'Eligible Cardholders achieving the spend and transaction threshold (for the net amount spent excluding cancellation and refunds) will qualify for offer',
    'Corporate Cards, Commercial Cards, Dealer Cards, Distributor Cards, Business Cards, Value Plus Cards and Staff Cards are excluded from the promotion.',
    'Card Holder/s whose account has been classified as delinquent before or during the currency of the Program, or when the gift is to be collected, will not be eligible for the benefits of the Program.',
    'This offer is non-encashable, not extendable and non-negotiable.',
    'If a card member has more than 1 (one) HDFC Bank Credit Card, spends on the cards cannot be clubbed by the card member in order to qualify for the said offer.',
    'Returned purchases, disputed or unauthorized /fraudulent transactions, EMI’s and Card Account Fees will not be considered for this offer.',
    'This offer is valid for Indian residents and citizens only.',
    'Nothing herein amounts to a commitment by HDFC Bank to conduct further, similar or other offers.',
    'Rs 1 payment will be required to be done through your eligible HDFC Bank Credit Card to claim the benefits. This amount will not be refunded.',
    'HDFC Bank Cardholders are not bound in any way to participate in this offer. Any participation is voluntary and the offer is being made purely on a ”best effort“ basis.',
    'Above offers is by way of a special offer for select HDFC Bank Credit Card members only and nothing contained herein shall prejudice or affect the terms and conditions of the Card member agreement. The terms of the above offers shall be in addition to and not in derogation of the terms contained in the Card members Agreement.',
    'HDFC Bank reserves the right, at any time, without prior notice and without assigning any reason whatsoever, to add / alter / modify / change or vary all of these terms and conditions or to replace, wholly or in part, this offer by another offer, whether similar to this offer or not, or to extend or withdraw it altogether.',
    'All disputes, if any, arising out of or in connection with or as a result of above offers or otherwise relating hereto shall be subject to the exclusive jurisdiction of the competent courts / tribunals in Chennai only, irrespective of whether courts / tribunals in other areas have concurrent or similar jurisdiction.',
    'Transactions that get reversed later due to technical faults or other considerations like incorrect incomplete or faulty buy etc from merchant end wont be taken to consideration for winner qualification. HDFC Bank reserves the right, at any time in such cases to judge the appropriateness of such transactions.',
    'Cards if closed voluntary or otherwise during the voucher fulfilment period shall not be eligible for the said vouchers',
  ];
  public title = 'Terms and Conditions';

  public constructor(
    public meta: Meta,
    public pageTitle: Title
  ) {}

  public ngOnInit(): void {
    const des = 'Terms and Conditions';
    this.pageTitle.setTitle(this.title);
    this.meta.updateTag({ name: 'description', content: des });
  }
}
