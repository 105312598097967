import { GiftVouchersConfig } from '@pv-frontend/gift-voucher-journey';

import { giftVouchersConfig } from './gift-vouchers';

export const giftVouchersDesktopConfig: GiftVouchersConfig = {
  ...giftVouchersConfig,

  landingPage: {
    ...giftVouchersConfig.landingPage,
    categoryAvatarStyle: {
      ...giftVouchersConfig.landingPage.categoryAvatarStyle,
      label: {
        ...giftVouchersConfig?.landingPage.categoryAvatarStyle?.label,
        paddingTop: '8px',
      },
      imageOrInitialsContainer: {
        ...giftVouchersConfig?.landingPage.categoryAvatarStyle
          ?.imageOrInitialsContainer,
        container: {
          width: '113px',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
        },
        image: {
          width: '72px',
          height: '72px',
        },
      },
    },
    personalisedCardStyle: {
      cardContainer: {
        width: '256px !important',
        height: '334px !important',
        minWidth: '256px !important',
        minHeight: '334px !important',
      },
      title: {
        fontSize: '12px',
      },
      subTitle: {
        paddingTop: 0,
        fontSize: '17px',
        fontWeight: 600,
      },
      image: {
        width: '256px',
        height: '160px',
      },
      cardContent: {
        border: '1px solid var(--surface-text-placeholder-low-contrast)',
        maxHeight: '174px',
        fontSize: '12px',
        'grid-template-rows': '1fr 1rem 2rem',
        padding: '24px 16px 4px 16px',
      },
      information: {
        attentionText: {
          color: 'var(--supporting-moss-700)',
          fontSize: '15px',
        },
        primaryText: {
          fontSize: '15px',
        },
        strikeThroughText: {
          fontSize: '15px',
        },
        baseText: {
          fontSize: '15px',
        },
      },
    },
    personalisedCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '6rem',
          transform: 'rotate(0deg)',
          width: '10rem',
          height: '10rem',
        },
        title: {
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        card: {
          margin: '1.5rem 0 0',
          padding: '1.5rem 5rem 1.5rem',
        },
      },
    },
    limitedTimeCardSectionStyle: {
      ...giftVouchersConfig.landingPage.limitedTimeCardSectionStyle,
      style: {
        ...giftVouchersConfig?.landingPage?.limitedTimeCardSectionStyle?.style,
        title: {
          ...giftVouchersConfig.landingPage.limitedTimeCardSectionStyle?.style
            ?.title,
          image: {
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          backgroundColor: 'var(--surface-e)',
          margin: '0',
          padding: '.3rem 5rem .5rem',
        },
      },
    },
    // TODO: the below code blocks to be further optimized by extending giftVouchersConfig more efficiently
    mostSearchedSectionStyle: {
      style: {
        title: {
          container: {
            paddingLeft: '0.8rem',
          },
          image: {
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        card: {
          backgroundColor: 'var(--surface-e)',
          margin: '1rem 0 0',
          padding: '0 5rem',
        },
      },
    },
    merchantAvatarStyle: {
      style: {
        label: {
          lineHeight: '1.2rem',
          overflow: 'hidden',
          display: '-webkit-box',
          webkitBoxOrient: 'vertical',
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          webkitLineClamp: '1',
          width: '100%',
          fontSize: '1.125rem',
          paddingTop: '.8rem',
        },
        container: {
          paddingBottom: '1rem',
        },
        imageOrInitialsContainer: {
          container: {
            width: '12rem',
            height: 'auto',
            display: 'flex',
            justifyContent: 'center',
          },
          image: {
            width: '7.5rem',
            height: '7.5rem',
          },
        },
      },
    },
    bestSellerCardSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          container: {
            paddingLeft: '0.8rem',
          },
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          backgroundColor: 'rgba(0, 156, 92, 0.09)',
          margin: '0',
          padding: '.6rem 5rem 1.5rem',
        },
      },
    },
    bestSellerCardStyle: {
      style: {
        cardContainer: {
          width: '100% !important',
          height: '100% !important',
          border: '.4px solid var(--feedback-border-positive-high-contrast)',
        },
        title: {
          color: 'var(--surface-text-normal-low-contrast)',
          fontSize: '1.125rem',
        },
        cardContent: {
          padding: '0.75rem 0.75rem 2rem',
        },
        footer: {
          background:
            'linear-gradient(90deg, rgba(0, 140, 177, 0.12) 13.92%, rgba(0, 140, 177, 0) 111.39%)',
          position: 'absolute',
          width: '100%',
          bottom: '0',
          fontSize: '.95rem',
        },
      },
    },
    categoryOfferSectionStyle: {
      style: {
        backgroundImage: {
          position: 'absolute',
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          image: {
            fill: 'var(--green-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        link: {
          fontWeight: '700',
          fontSize: '1rem',
        },
        card: {
          margin: '0',
          padding: '.5rem 5rem .5rem',
        },
      },
    },
    topMembershipSectionStyle: {
      style: {
        backgroundImage: {
          top: '-15px',
          right: '1rem',
          transform: 'rotate(0deg)',
          width: '6rem',
          height: '6rem',
        },
        title: {
          image: {
            fill: 'var(--blue-600)',
            marginRight: '.2rem',
            height: '1.5rem',
          },
        },
        card: {
          padding: '.5rem 5rem .5rem',
        },
      },
      config: {
        buttonLabel: 'View all',
        emphasizedTitleText: 'Top Memberships',
      },
    },
    offersSectionStyle: {
      style: {
        cardSection: {
          backgroundImage: {
            position: 'absolute',
            top: '-.9rem',
            right: '1rem',
            transform: 'rotate(0deg)',
            width: '7rem',
            height: '7rem',
          },
          title: {
            image: {
              fill: 'var(--green-600)',
              marginRight: '.2rem',
              height: '1.5rem',
            },
          },
          card: {
            backgroundColor: 'var(--surface-e)',
            margin: '.5rem 0 0',
            padding: '0 5rem 1.5rem',
          },
        },
        categoryBasedOffers: {
          card: {
            style: {
              image: {
                minHeight: '107px',
              },
            },
          },

          chip: {
            style: {
              inActive: {
                container: {
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  border: 'unset',
                  color: 'var(--surface-text-subdued-low-contrast)',
                },
                label: {
                  fontWeight: '700',
                },
              },
              active: {
                container: {
                  width: 'auto',
                  whiteSpace: 'nowrap',
                  border: 'unset',
                  color: 'var(--surface-a)',
                  backgroundColor: 'var(--primary-color)',
                },
                label: {
                  fontWeight: '700',
                },
              },
            },
          },
        },
      },
    },
    banners: {
      config: {
        data: [
          {
            redirectUrl: '',
            imageUrl: './assets/Blog-Banner-Examples.webp',
            isExternalUrl: false,
          },
        ],
        showPageTopBanner: true,
        showPageMiddleBanner: true,
      },
    },
  },
  shellListing: {
    ...giftVouchersConfig.shellListing,
    gridContainer: {
      style: {
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        gridTemplateColumns: 'repeat(auto-fit, minmax(256px, 1fr))',
        display: 'grid',
      },
    },
    card: {
      style: {
        cardContainer: { maxHeight: '360px', height: '360px', width: '256px' },
        cardContent: { minHeight: '143px' },
        image: { height: '180px' },
        title: { fontSize: '18px', fontWeight: 800, color: 'var(--black)' },
        subTitle: { fontSize: '16px', fontWeight: 200, color: 'var(--black)' },
        information: {
          baseText: { color: 'var(--primary-color)' },
          attentionText: { color: 'var(--primary-color)' },
        },
      },
    },
  },
  header: {
    ...giftVouchersConfig.header,
    container: {
      style: {
        background: 'var(--primary-color)',
        padding: '10px 15%',
      },
    },
    logo: {
      config: {
        url: './assets/HDFC-logo.png',
        onLogoClickNavigationURL: '/',
      },
    },
  },
};
