import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PvAuthenticationModule } from '@pv-frontend/authentication';
import { GvRedemptionModule } from '@pv-frontend/gift-voucher-journey';
import { PvButtonModule } from '@pv-frontend/pv-shared-components/button';
import { PvCardModule } from '@pv-frontend/pv-shared-components/card';
import { PvChipModule } from '@pv-frontend/pv-shared-components/chip';
import { PvDrawerModule } from '@pv-frontend/pv-shared-components/drawer';
import { SvgIconModule } from '@pv-frontend/pv-shared-components/svg-icon';
import { CarouselModule } from 'primeng/carousel';

import { BenefitExplainerModule } from '../benefits-explainer/benefits-explainer.module';

import { BenefitsCarouselComponent } from './benefits-carousel/benefits-carousel.component';
import { HomeComponent } from './home.component';
import { UpdateEmailComponent } from './update-email/update-email.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SvgIconModule,
    PvButtonModule,
    PvAuthenticationModule,
    CarouselModule,
    PvCardModule,
    PvChipModule,
    PvDrawerModule,
    BenefitExplainerModule,
    GvRedemptionModule,
    RouterModule.forChild([
      {
        path: '',
        component: HomeComponent,
      },
    ]),
  ],
  declarations: [
    HomeComponent,
    BenefitsCarouselComponent,
    UpdateEmailComponent,
  ],
  exports: [HomeComponent],
})
export class HomeModule {}
