<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div
  class="unlocked-bundle-fold relative z-1 bg-white w-full flex justify-content-center flex-column flex-wrap overflow-hidden">
  <ng-content select="[titleTemplateContent]"></ng-content>
  <div
    #stepsContainerRef
    class="redemption-steps-card-container pv-grid relative w-full pv-py-18px pv-my-1px justify-content-center align-items-center">
    <ng-content></ng-content>
  </div>
  <div
    *ngIf="showArrows"
    class="hidden backward-forward w-10 md:flex justify-content-start align-items-center">
    <div
      class="circle flex justify-content-center align-items-center cursor-pointer"
      (click)="scrollHorizontal(true)"
      [ngClass]="{ active: isStepsContainerMinWidthReached }">
      <pv-svg-icon name="back"></pv-svg-icon>
    </div>
    <div
      class="circle flex justify-content-center align-items-center pv-ml-13px cursor-pointer"
      (click)="scrollHorizontal(false)"
      [ngClass]="{ active: isStepsContainerMaxWidthReached }">
      <pv-svg-icon
        name="back"
        [style]="{ transform: 'rotate(180deg)' }"></pv-svg-icon>
    </div>
  </div>
</div>
