import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { GvmDetailsModule } from '@pv-frontend/gift-voucher-journey';

import { GVMSRedemptionComponent } from './gvms-redemption/gvms-redemption.component';
const routes: Routes = [
  {
    path: 'sso_login',
    loadChildren: () =>
      import('@pv-frontend/sso-login').then((m) => m.SSOModule),
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login-page/login-page.module').then((m) => m.LoginPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search-container/search-container.module').then(
        (m) => m.SearchContainerModule
      ),
  },
  {
    path: 'gvms/details/:id',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.GvmDetailsModule
      ),
  },
  {
    path: 'gvms-redeem',
    component: GVMSRedemptionComponent,
  },
  {
    path: 'gvms',
    component: GVMSRedemptionComponent,
  },
  {
    path: 'order-status',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.OrderStatusModule
      ),
  },
  {
    path: 'giftcards/checkout-message',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.OrderStatusModule
      ),
  },
  {
    path: 'memberships/checkout-message',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.OrderStatusModule
      ),
  },
  {
    path: 'order-history',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.OrderHistoryModule
      ),
  },
  {
    path: 'order-details',
    loadChildren: () =>
      import('@pv-frontend/gift-voucher-journey').then(
        (m) => m.OrderDetailsModule
      ),
  },
  {
    path: 'info',
    loadChildren: () =>
      import('../../../hdfc-rupay/src/app/info/info.module').then(
        (m) => m.InfoModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('../../../hdfc-rupay/src/app/info/info.module').then(
        (m) => m.InfoModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
