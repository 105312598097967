import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactUsComponent } from './contact-us/contact-us.component';
import { InfoComponent } from './info.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: InfoComponent,
    children: [
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      { path: 'customer-support', component: ContactUsComponent },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [
    InfoComponent,
    TermsAndConditionsComponent,
    ContactUsComponent,
  ],
})
export class InfoModule {}
