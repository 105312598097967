<pv-drawer
  [isVisible]="true"
  [showCloseIcon]="false"
  [contentTemplate]="emailUpdate"
  [showBackdrop]="true"
  [dismissOnBackdropClick]="false"
  [closeOnEscape]="false"
  [style]="pvDrawerConfig"
  class="">
  <ng-template #emailUpdate>
    <div
      class="sign-in-inputs pv-px-10px pv-pt-30px pv-pb-10px flex h-full justify-content-end align-items-start flex-column">
      <div
        class="logo-img flex justify-content-center align-items-center pv-pt-10px pv-pb-30px w-full">
        <img src="../../../assets/HDFC-logo.png" alt="HDFC complete logo" />
      </div>
      <div class="mb-3 text-sm text-center pv-text-20px font-bold">
        Email ID
      </div>

      <div class="email-cta pv-text-16px">
        <div class="text-sm text-left font-bold">
          Enter your registered HDFC Email-ID.
        </div>
        <div class="mb-4 text-sm text-left font-normal">
          Get Gift Card details over email.
        </div>
      </div>
      <form class="w-full" style="min-height: 100px" [formGroup]="emailIdForm">
        <div
          class="pv-mobile-input-box surface-action-icon-low-contrast pv-border-solid p-1 border-round-xs flex flex-row align-items-center text-sm bg-white pv-px-10px">
          <input
            name="emailId"
            pInput
            formControlName="emailId"
            class="pv-mobile-input-ele w-full h-full text-sm bg-transparent p-0 border-none pv-text-14px text-color"
            placeholder="Enter your Email Id"
            type="email"
            autocomplete="on" />
        </div>
        <div class="text-red-500 pv-text-12px text-left mt-3">
          {{ emailErrorMessage }}
        </div>
      </form>
      <pv-button
        class="w-full"
        styleClass="w-full"
        label="
      Continue"
        [isDisabled]="!emailIdForm.controls.emailId.value"
        (clickEvent)="sendEmail()"></pv-button>
    </div>
  </ng-template>
</pv-drawer>
