<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<div class="relative md:pv-mb-40px">
  <div
    class="banner-fold flex flex-column justify-content-center align-items-center overflow-hidden relative bg-primary">
    <img
      class="dots-image absolute opacity-30 bottom-0 left-0 hidden md:block"
      src="../../assets/DOTS.png"
      alt="dots" />
    <div
      class="complete-pane hidden w-9 md:flex justify-content-between md:align-items-center z-1">
      <div class="left-pane pv-pt-40px">
        <ng-container *ngTemplateOutlet="welcomeBackTempalte"></ng-container>
        <ng-container *ngTemplateOutlet="pennydropCTACard"></ng-container>
      </div>
      <div class="right-pane">
        <img
          class="rupay-credit-card-image"
          src="../../assets/RupayCard.png"
          alt="Rupay card" />
      </div>
    </div>
    <div
      class="complete-pane-mobile flex md:hidden flex-column justify-content-start align-items-center w-full">
      <ng-container *ngTemplateOutlet="welcomeBackTempalte"></ng-container>
    </div>
  </div>
  <div
    class="carousel-template-mobile-container bg-white relative md:hidden pv-mb-16px">
    <div class="absolute carousel-template w-full z-3">
      <ng-container *ngTemplateOutlet="pennydropCTACard"></ng-container>
    </div>
  </div>
  <ng-template
    #listingSection
    let-title="title"
    let-imageTemplate="imageTemplate">
    <hdfc-rupay-benefits-carousel [showArrows]="false" [title]="title">
      <div
        titleTemplateContent
        class="flex pv-mb-16px w-full md:flex-column flex-row md:flex-row text-left pv-text-30px font-bold font-bold md:pv-text-24px md:pv-mb-22px text-black-alpha-90 relative">
        <span class="pv-mb-8px md:m-0">{{ title }}</span>
        <img
          class="flower-badge block md:hidden"
          src="../../assets/flower-badge.png"
          alt="Flower badge" />
      </div>
      <ng-container *ngTemplateOutlet="cardsListingTemplate"></ng-container>
    </hdfc-rupay-benefits-carousel>
  </ng-template>

  <ng-template #imageTemplateBurst>
    <!-- <img class="absolute top-0 right-0" src="../../assets/burst-pucker.svg" alt="burst" /> -->
  </ng-template>

  <ng-template #cardsListingTemplate>
    <ng-container *ngIf="parsedResponse.length">
      <ng-container *ngFor="let benefit of parsedResponse">
        <div class="md:pv-mr-20px relative pv-m-12px">
          <pv-card
            [offerImage]="benefit.headerImage"
            [information]="{ baseText: 'Claim Now' }"
            [title]="benefit.merchantName ?? ''"
            [subTitle]="benefit.title"
            [cardRibbon]="benefit.cardRibbon ?? null"
            [metaInformation]="benefit?.cardMeta"
            (cardClickHandler)="
              handleRedeemWrapperGCAndMemberships(
                benefit.cardMeta.id,
                benefit.title,
                benefit.isUnlockedBenefitApplicable
              )
            "
            [style]="moduleConfig?.personalisedCardStyle"
            [cardBanner]="
              benefit.benefitType === 'Membership'
                ? (moduleConfig?.membershipTag ?? null)
                : null
            "
            cardType="v1"
            [analyticsEnabledForCardButton]="true"
            [labelForAnalyticsCard]="
              gtmLabels.landing.personalisedOffer
            "></pv-card>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!parsedResponse.length">
      <div
        class="flex w-full h-full flex justify-content-center align-items-center pv-text-20px font-light pv-py-40px">
        No Benefits Available
      </div>
    </ng-container>
  </ng-template>
  <ng-container
    *ngTemplateOutlet="
      listingSection;
      context: {
        title: 'Claim any of these vouchers for free',
        imageTemplate: imageTemplateBurst
      }
    "></ng-container>

  <ng-template #welcomeBackTempalte>
    <div class="welcome-back-holder text-center md:text-left pv-mb-40px">
      <div class="title pv-text-20px md:pv-mb-5px font-semibold">
        Welcome back to
      </div>
      <div
        class="pv-text-32px font-semibold md:pv-line-height-38px md:pv-text-40px pv-mb-24px">
        HDFC BANK
      </div>
      <div
        *ngIf="!arePointsLoading"
        class="flex justify-content-start align-items-center md:pv-text-26px">
        You have
        <pv-chip
          *ngIf="usePoints"
          class="pv-mx-6px"
          [label]="
            (usePoints.toString() | currency: 'INR' : '' : '1.0' : 'en-IN') ??
            ''
          "
          [isRounded]="true"
          [size]="'normal'"
          [style]="customCurrencyRupeeChipStyle"
          [isActive]="true"
          [categoryIcon]="{
            url: './assets/custom-currency-rupee.svg',
            position: 'start'
          }"
          [showCrossIcon]="false"></pv-chip>
        Credits
      </div>
    </div>
  </ng-template>
  <ng-template #pennydropCTACard>
    <div
      *ngIf="!isPennyDropStatusLoading && isPennyDropComplete"
      (click)="initiatePennyDrop()"
      class="penny-drop-cta cursor-pointer pv-text-14px md:pv-text-16px text-white bg-green-600 text-center">
      Your card is
      <br />
      successfully validated
    </div>
  </ng-template>
</div>
<gv-redemption
  *ngIf="showGvRedemption"
  [showRedemption]="showGvRedemption"
  [showCheckout]="false"
  [benefitId]="selectedBenefitId"
  [isBenefitOffer]="isBenefitOffer"
  [selectedOfferId]="offerId"
  (redemptionClosed)="handleGVRedemptionClose($event)"></gv-redemption>

<pv-drawer
  class="login-alert-drawer"
  [isVisible]="isAskForPennyDropOpen"
  position="bottom"
  [showCloseIcon]="false"
  [contentTemplate]="contentTemplate"
  [showBackdrop]="true"
  [dismissOnBackdropClick]="true"
  [closeOnEscape]="false"
  styleClass="p-0"
  [style]="askForPennyDropModalStyles"
  (closeHandler)="dismissPennyDropModal()">
  <ng-template #contentTemplate>
    <div
      class="ask-for-penny-drop-card flex h-full w-full justify-content-between flex-column align-items-center pv-px-27px">
      <div class="ask-for-penny-drop-card-main-text text-center">
        <div
          class="ask-for-penny-drop-card-header pv-text-27px font-bold pv-line-height-36px">
          Validate Your Card
        </div>
        <div
          class="ask-for-penny-drop-card-sub-title pv-text-16px pv-line-height-23px surface-text-subtle-low-contrast">
          Complete
          <strong>₹1</strong>
          Transaction to claim
          <br />
          your free rewards
        </div>
      </div>
      <div class="img-container">
        <img
          src="../../assets/penny-drop-success-coin.png"
          alt="Penny drop success coin" />
      </div>
      <div class="bottom-container w-full">
        <pv-button
          styleClass="w-full"
          [style]="{ borderRadius: '9px' }"
          label="Continue"
          (clickEvent)="handleClosePennyDropModal()"></pv-button>
      </div>
    </div>
  </ng-template>
</pv-drawer>

<hdfc-rupay-update-email
  *ngIf="showEmailVerificationModal"
  (emailSavedSuccessfullyEvent)="
    handleEmailSavedSuccessfullyEvent($event)
  "></hdfc-rupay-update-email>
