import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BenefitRedemptionQueryParams } from '@pv-frontend/gift-voucher-journey';

@Component({
  selector: 'hdfc-rupay-gvms-redemption',
  templateUrl: './gvms-redemption.component.html',
  styleUrls: ['./gvms-redemption.component.scss'],
})
export class GVMSRedemptionComponent {
  public showAuthenticationModal = false;
  public showTokenExpiredModal = false;
  public showGvRedemption = false;
  public isBenefitOffer = false;
  public selectedBenefitId = '';
  public isClientBannerLoading = false;
  public offerId?: string;
  public showGVMembershipCheckout = false;
  public constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.handleGVRedemption(params as BenefitRedemptionQueryParams);
    });
  }

  public handleGVRedemptionClose(showRedemption: boolean): void {
    this.showGvRedemption = showRedemption;
    this.selectedBenefitId = '';
  }

  public handleGVRedemption(obj: BenefitRedemptionQueryParams): void {
    const { merchantId, merchantName, isBenefitOffer, offerId, checkout } = obj;
    const queryParams = {
      merchantId,
      merchantName,
      isBenefitOffer,
      offerId,
      checkout,
    };
    this.handleRedemption(queryParams);
  }

  private handleRedemption(queryParams: BenefitRedemptionQueryParams): void {
    if (queryParams.merchantId) {
      this.selectedBenefitId = queryParams.merchantId;
      this.isBenefitOffer = queryParams.isBenefitOffer
        ? JSON.parse(queryParams.isBenefitOffer.toString())
        : false;
      if (this.isBenefitOffer && queryParams?.offerId)
        this.offerId = queryParams.offerId;
    }
    this.showGvRedemption = !!queryParams.merchantId;
    this.showGVMembershipCheckout = !!queryParams.checkout;
  }
}
